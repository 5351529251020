.site-logo {
  min-width: 125px;
  width: rfs-fluid-value(8rem);
  height: auto;
}

body:has(#navbarMain.show) {
  overflow: hidden;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

.navbar-nav {
  @include media-breakpoint-up(lg) {
    .dropdown-menu.show {
      margin-top: -1px;
      position: absolute;
      width: 100%;
    }

    gap: rfs-fluid-value(2em);
  }
}

.navbar-brand {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.hbz-header_section {
  .hbz-header_section-wrapper {
    @include hbz_section($padInlineOnly: true);

    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  background-color: #fff;
}

.hbz-header_section.hbz-header_section_top {
  @include media-breakpoint-down(lg) {
    display: none;
  }

  background-color: white;
  padding-block: rfs-fluid-value(calc(0.5 * $spacer));
}

.hbz-header_section.hbz-header_section_nav {
  @include media-breakpoint-up(lg) {
    background-color: map-get($hbz-neutral-seq, "5");
  }
}

.hbz-header_logo {
  a {
    display: inline-block;
  }
}

.hbz-secondarynav {
  li {
    a {
      &:focus,
      &:hover {
        &::before {
          background: adjust-color(
            map-get($hbz-primary-seq, "40"),
            $alpha: -0.85
          );
          border-radius: 1em;
          content: "";
          display: block;
          height: 180%;
          position: absolute;
          top: -40%;
          width: 180%;
        }

        span {
          opacity: 1;
        }
      }

      span {
        font-size: rfs-fluid-value(0.8rem);
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        transition: bottom 0.15s ease-in-out;
        white-space: nowrap;
      }

      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: rfs-fluid-value(1.5rem);
      position: relative;
    }

    line-height: unset;
    list-style-type: none;
  }

  --#{$prefix}nav-link-disabled-color: #{map-get($hbz-neutral-seq, "40")};
  display: flex;
  gap: rfs-fluid-value(2rem);
}

.hbz-nav_secondarynav {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  a {
    & svg + span,
    & img + span {
      margin-left: rfs-fluid-value(calc($spacer / 2));
    }

    --#{$prefix}nav-link-disabled-color: #{map-get($hbz-neutral-seq, "40")};
  }

  margin-top: rfs-fluid-value(calc($spacer * 5));
}

.hbz-dropdown-menu {
  @include media-breakpoint-up(lg) {
    @include hbz_section($padInlineOnly: true);
    padding-block: $hbz-inline-padding;
  }
  @include hbz_section($padInlineOnly: true);

  margin: auto;
  max-width: 1280px;
  padding: 0;
}

.hbz-sitenav {
  --#{$prefix}navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16'%3e%3cpath fill='%230000c0' fill-rule='evenodd' d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5'%3e%3c/path%3e%3c/svg%3e");

  .navbar-toggler {
    &:focus-visible {
      outline: 2px solid $hbz-tertiary;
      box-shadow: none;
      // oder box-shadow? wird beides in bootstrap überschrieben
    }
    &[aria-expanded="true"] {
      --#{$prefix}navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16'%3e%3cpath fill='%230000c0' stroke-miterlimit='1' stroke-width='0' d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z'/%3e%3c/svg%3e");
    }
    border: none;
    color: map-get($hbz-primary-seq, "100");
    font-size: 2rem;
    padding: 0;
  }
  .navbar-collapse {
    @include media-breakpoint-down(lg) {
      height: calc(100vh - 100%);
      left: calc(-1 * $hbz-inline-padding);
      overflow: scroll;
      position: absolute;
      right: calc(-1 * $hbz-inline-padding);
      top: 100%;
      width: auto;

      background-color: map-get($hbz-primary-seq, "100");
      padding: $hbz-inline-padding;
    }
  }

  .nav-item {
    &:focus-visible {
      outline: 2px solid $hbz-tertiary;
      box-shadow: none;
      // oder box-shadow? wird beides in bootstrap überschrieben
    }
    &.dropdown {
      .dropdown-menu {
        @include media-breakpoint-up(lg) {
          box-shadow: 0 16px 16px rgba(0, 0, 0, 0.16);
        }
        --#{$prefix}dropdown-bg: #{map-get($hbz-primary-seq, "100")};
        --#{$prefix}dropdown-link-color: #{$white};
        --#{$prefix}dropdown-link-hover-bg: #{map-get($hbz-primary-seq, "100")};
        --#{$prefix}dropdown-link-hover-color: #{$white};
        border: 0;
      }
      position: initial;
    }
    .dropdown-item {
      &:hover {
        text-decoration: underline dotted;
        text-decoration-thickness: 1px;
        text-underline-offset: 5px;
      }
    }

    font-size: rfs-fluid-value(1.125rem);
  }

  .nav-link {
    &:focus-visible {
      outline: 2px solid $hbz-tertiary;
      box-shadow: none;
      // oder box-shadow? wird beides in bootstrap überschrieben
    }
    &:hover {
      text-decoration: underline dotted;
      text-decoration-thickness: 1px;
      text-underline-offset: 5px;
    }

    --#{$prefix}nav-link-color: #{$white};
    --#{$prefix}nav-link-hover-color: #{$white};
    --#{$prefix}navbar-active-color: #{$white};
    font-weight: 500;
    padding-inline: 0;
  }

  @include media-breakpoint-up(lg) {
    .nav-link {
      &::before {
        background-color: adjust-color(
          map-get($hbz-primary-seq, "40"),
          $alpha: -0.85
        );
        border-radius: 1em;
        content: "";
        height: 100%;
        left: -1rem;
        padding: 0 1rem;
        position: absolute;
        right: -1rem;
        top: 0;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: all 0.15s ease-out;
        z-index: -1;
      }

      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }

      &.dropdown-toggle {
        &.show {
          &::before {
            background-color: map-get($hbz-primary-seq, "100");
            border-radius: 1em 1em 0 0;
            transform: scaleX(1);
          }

          color: $white;
        }
      }

      --#{$prefix}nav-link-color: #{map-get($hbz-primary-seq, "100")};
      --#{$prefix}nav-link-hover-color: #{map-get($hbz-primary-seq, "100")};
      --#{$prefix}navbar-active-color: #{map-get($hbz-primary-seq, "100")};
      --#{$prefix}navbar-nav-link-padding-x: 0;
      position: relative;
      z-index: 1;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-inline: auto;
    max-width: 1280px;
    position: unset;
    width: 100%;
  }

  padding-block: 0;
  width: 100%;
}
