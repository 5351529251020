.hbz-footer {
  a {
    display: inline-block;
  }
}

.hbz-footer_upper {
  .hbz-footer_upper-inner {
    @include hbz_section();
  }

  background-color: map-get($hbz-primary-seq, "100");
  color: color-contrast(map-get($hbz-primary-seq, "100"));
}

.hbz-footer_lower {
  .hbz-footer_lower-inner {
    @include hbz_section($padInlineOnly: true);
  }

  background-color: map-get($hbz-primary-seq, "110");
  color: color-contrast(map-get($hbz-primary-seq, "110"));
  font-size: $font-size-sm;
  font-weight: 500;
  padding-block: $hbz-inline-padding;
}

.hbz-logo-footer {
  width: 95%;
}