html {
  hyphens: auto;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html body ::selection {
  background: map-get($hbz-accent-seq, "80");
  color: $white;
}

.bi {
  fill: currentColor;
  height: auto;
  width: 1em;
}

.list-inline-item:not(:last-child) {
  margin-inline-end: rfs-fluid-value($spacer);
}

:last-child {
  margin-block-end: 0;
}

a.disabled,
a[disabled],
a[aria-disabled="true"] {
  img,
  svg {
    filter: grayscale(100%);
    opacity: 0.75;
  }

  cursor: default;
}
