$hbz-block-padding: rfs-fluid-value(calc(2 * $spacer));
$hbz-inline-padding: rfs-fluid-value(calc(1.5 * $spacer));

@mixin hbz_section($padInlineOnly: false) {
  margin: auto;
  max-width: 1280px;

  @if $padInlineOnly == false {
    padding-block: $hbz-block-padding;
  }
  padding-inline: $hbz-inline-padding;
}

@mixin hbz_body_link {
  &:hover {
    text-decoration-style: dotted;
  }

  text-decoration-color: currentColor;
  text-decoration-line: none;
  text-decoration-style: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}
