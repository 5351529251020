body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 160%;
}

main {
  p,
  li {
    max-width: 50rem;
  }

  flex-grow: 1;
}

.section-container {
  section {
    @include hbz_section;
  }
  padding: none;
}
