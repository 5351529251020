@font-face {
    font-display: swap;
    font-family: "WixMadeforText";
    font-style: normal;
    font-weight: normal;
    src: url('/assets/wix/WixMadeforText-Regular.ttf') format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "WixMadeforText";
    font-style: italic;
    font-weight: normal;
    src: url('/assets/wix/WixMadeforText-Italic.ttf') format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "WixMadeforText";
    font-style: normal;
    font-weight: 500;
    src: url('/assets/wix/WixMadeforText-SemiBold.ttf') format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "WixMadeforText";
    font-style: italic;
    font-weight: 500;
    src: url('/assets/wix/WixMadeforText-SemiBoldItalic.ttf') format("truetype");
}
