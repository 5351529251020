.hbz-header_logo {
  padding-block: rfs-fluid-value($spacer);
}
.site-logo {
  width: rfs-fluid-value(12rem);
}

.hbz-heroSection {
  background-image: none !important;
}

#map {
  @include media-breakpoint-up(md) {
    --#{$prefix}aspect-ratio: 100%;
  }
  @include media-breakpoint-up(lg) {
    --#{$prefix}aspect-ratio: calc(3 / 4 * 100%);
  }
  @include media-breakpoint-up(xxl) {
    --#{$prefix}aspect-ratio: 100%;
  }
  // @include media-breakpoint-up(xl) {
  //     --bs-aspect-ratio: calc(3 / 4 * 100%);
  // }

  --#{$prefix}aspect-ratio: calc(3 / 4 * 100%);
  border-radius: rfs-fluid-value($spacer);
}

#locationsSearch {
  background-color: map-get($hbz-neutral-seq, "5");
  border-radius: rfs-fluid-value($spacer);
  padding: rfs-fluid-value($spacer);
}

#q + div > button {
  border: none !important;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 100;
}

#q:valid + div > button {
  visibility: visible;
}
#q:invalid + div > button {
  visibility: hidden;
}

.hbz-footer {
  a {
    display: inline-block;
  }
}

.hbz-location-list {
  & > li {
    break-inside: avoid;
    break-inside: avoid-column;
  }

  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-up(lg) {
    column-count: 3;
  }

  column-count: 1;
  column-gap: rfs-fluid-value(calc(2 * $spacer));
  column-rule: 1px solid map-get($hbz-neutral-seq, "5");
}

.hbz-nav_secondarynav a[data-lng-switch] img {
  height: 1em;
}

.hbz-secondarynav a[data-lng-switch] img {
  height: 0.66em;
}
