main {
  a {
    @include hbz_body_link;
  }
}

footer {
  a {
    @include hbz_body_link;

    color: $white;
  }
}

a svg {
  transition: transform 0.25s ease-in-out;
}

a:hover svg,
a:focus svg {
  transform: rotate(-45deg);
}

main a svg + span,
footer a svg + span {
  margin-left: rfs-fluid-value(calc($spacer / 2));
}
