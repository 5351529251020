$font-family-sans-serif: "WixMadeforText", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$hbz-primary:   #004678;
$hbz-secondary: #8f00c7;
$hbz-tertiary:  #00e9ff;
$hbz-accent:    #ff7800;
$hbz-neutral:   #000000;

$hbz-primary-seq: (
    "110": shade-color($hbz-primary, 10%),
    "100": $hbz-primary,
    "80":  #045bdf,
    "60":  #4383ff,
    "40":  #6aa6ff,
    "20":  #95c6ff,
    "10":  #c2deff,
    "5":   #e0eeff,
    "3":   #f1f7ff,
);

$hbz-secondary-seq: (
    "110": #7a00a9,
    "100": #8f00c7,
    "80":  #a533d2,
    "60":  #bc66dd,
    "40":  #d299e9,
    "20":  #e9ccf4,
    "10":  #eed9f7,
    "5":   #f9f2fc,
    "3":   #fcf7fd,  
);

$hbz-accent-seq: (
    "100": #ff7800,
    "80":  #ff9333,
    "60":  #ffae66,
    "40":  #ffc999,
);

$hbz-neutral-seq: (
    "100": #000000,
    "80":  #333333,
    "60":  #666666,
    "40":  #999999,
    "20":  #cccccc,
    "10":  #e6e6e6,
    "5":   #f2f2f2,
    "3":   #f7f7f7,
    "0":   #ffffff,
);

$primary:       $hbz-primary !default;
$secondary:     $hbz-secondary !default;
$success:       #2fd67b !default;
$info:          #45bbff !default;
$warning:       #feaf75 !default;
$danger:        #ff5555 !default;

$font-size-base:        1.125rem;
$rfs-base-value:        0.825rem;
$link-decoration:       none;
$link-hover-decoration: underline;

$enable-grid-classes: false;
$enable-cssgrid: true;