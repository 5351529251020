.hbz-section {
}

.hbz-section_wrapper {
  @include hbz_section;
}

.hbz-section_head {
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: rfs-fluid-value(calc(2 * $spacer));
  }
}

.hbz-section_content {
}
