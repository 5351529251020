.hbz-heroSection {
  background-color: map-get($hbz-primary-seq, "100");
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNjIiIGhlaWdodD0iMjI2IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTYyIDIyNiI+PGcgY2xpcC1wYXRoPSJ1cmwoI3ByZWZpeF9fYSkiPjxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTY3LjU4IDEyMi43YTEyMy4wMiAxMjMuMDIgMCAwIDAtMTcuNTA5LTkuNTcxYzYuMTEtMi43MiAxMS45Ny01LjkzIDE3LjUwOS05LjU2OSA1LjE5IDIuODcgMTAuMTggNi4wNyAxNC45NCA5LjU3LTQuNzYgMy41LTkuNzUgNi43LTE0Ljk0IDkuNTdabTQ3LjI4LTkuNTdDMTQzLjQ1IDg0LjA0IDE2MS4xMSA0NC4xNyAxNjEuMTEuMjZoLTIxLjIzYzAgMzguNS0xNS42MyA3My40LTQwLjg4IDk4LjcyLTQuMzctMy40Mi04LjkzLTYuNjItMTMuNjQtOS41NyAyMy40OC0yMi40OSAzOC4xMy01NC4xNCAzOC4xMy04OS4xNWgtMjEuMjNjMCAzMS41Mi0xNC4zNCA1OS43Ni0zNi44MyA3OC41Mi01LjktMi42My0xMS45OC00LjkyLTE4LjIzLTYuODNDNzAuNDggNTYuNTggODUuODYgMzAuMTggODUuODYuMjZINjQuNjNjMCAzNS42MDQtMjguOTMyIDY0LjU3Mi02NC41MjMgNjQuNjNIMHYyMS4yM2guMTE1YzE1LjAyOS4wMTIgMjkuNSAyLjQgNDMuMDc1IDYuODItMTMuMDIzIDYuMS0yNy41MzMgOS41MjQtNDIuODE1IDkuNTc5SDBWMTIzLjc0bC4zNzUtLjAwMWMxNS4yODIuMDU1IDI5Ljc5MSAzLjQ3OSA0Mi44MTUgOS41OGguMDAxbC0uMDAxLjAwMWMtMTMuNTc2IDQuNDE5LTI4LjA0OSA2LjgwOC00My4wNzkgNi44MkgwdjIxLjIzaC4xMDRjMzUuNTkyLjA1NiA2NC41MjYgMjkuMDI0IDY0LjUyNiA2NC42M2gyMS4yM2MwLTI5LjkyLTE1LjM4LTU2LjMyLTM4LjY2LTcxLjY5IDYuMjUtMS45MSAxMi4zMy00LjIgMTguMjMtNi44M3YtLjAwMUM4Ny45MiAxNjYuMjQgMTAyLjI2IDE5NC40NyAxMDIuMjYgMjI2aDIxLjIzYzAtMzUuMDEtMTQuNjUtNjYuNjYtMzguMTMtODkuMTUgNC43MS0yLjk1IDkuMjctNi4xNSAxMy42NC05LjU3IDI1LjI1IDI1LjMyIDQwLjg4IDYwLjIyIDQwLjg4IDk4LjcyaDIxLjIzYzAtNDMuOTItMTcuNjYtODMuNzktNDYuMjUtMTEyLjg3WiIgY2xpcC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuMTUiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJwcmVmaXhfX2EiPjxyZWN0IHdpZHRoPSIxNjEuMTEiIGhlaWdodD0iMjI1Ljc0IiBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC4yNikiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  color: color-contrast(map-get($hbz-primary-seq, "100"));
  content: "";
}

.hbz-heroSection_wrapper {
  .hbz-heroSection_content {
    .hbz-pageHeading {
      padding-block: calc(2 * $hbz-block-padding);
    }

    .hbz-heroSection_content-right {
      & > img {
        height: 100%;
        margin-bottom: -1px;
        max-width: 780px;
        object-fit: cover;
        width: max(100%, calc(100% + ((100vw - 1280px) / 4)));
      }
    }

    gap: 2rem;
  }

  @include hbz_section($padInlineOnly: true);
  position: relative;
}
